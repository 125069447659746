import React, { Component } from 'react'

class Star extends Component {
  static defaultProps = {
    fillColor: '#00125f',
    height: 16,
    width: 16,
  }

  render() {
    const { className, fillColor, height, width } = this.props

    return (
      <svg
        className={className}
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Star</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          fillOpacity="0.4"
        >
          <g
            id="osqueryio-home-copy"
            transform="translate(-369.000000, -3130.000000)"
            fill={fillColor}
          >
            <g id="featured-community-projects" transform="translate(352.000000, 2673.000000)">
              <g id="bottom-left" transform="translate(0.000000, 417.000000)">
                <path
                  d="M24.7586701,53.6472637 L20.6008442,55.9382482 L20.6008442,55.9382482 C20.3589866,56.071513 20.0548898,55.9834812 19.921625,55.7416236 C19.867571,55.643523 19.8480215,55.5301138 19.8661104,55.4195772 L20.6713451,50.4989943 L20.6713451,50.4989943 C20.6970349,50.3420106 20.6464941,50.1821702 20.5352226,50.068493 L17.1068632,46.5660119 L17.1068632,46.5660119 C16.9137003,46.3686728 16.9170858,46.0521084 17.1144249,45.8589455 C17.1893027,45.7856523 17.2853138,45.7377343 17.3888982,45.721959 L22.0864669,45.0065468 L22.0864669,45.0065468 C22.2511738,44.9814629 22.3925664,44.8759878 22.4635443,44.7252571 L24.547611,40.2994685 L24.547611,40.2994685 C24.6652537,40.0496389 24.9631488,39.9424802 25.2129783,40.0601229 C25.3181602,40.1096521 25.4027947,40.1942866 25.4523239,40.2994685 L27.5363907,44.7252571 L27.5363907,44.7252571 C27.6073685,44.8759878 27.7487611,44.9814629 27.9134681,45.0065468 L32.6110368,45.721959 L32.6110368,45.721959 C32.8840315,45.7635345 33.0716338,46.018544 33.0300583,46.2915386 C33.014283,46.395123 32.9663649,46.4911341 32.8930717,46.5660119 L29.4647123,50.068493 L29.4647123,50.068493 C29.3534408,50.1821702 29.3029,50.3420106 29.3285898,50.4989943 L30.1338246,55.4195772 L30.1338246,55.4195772 C30.178421,55.6920947 29.9936545,55.9491663 29.7211371,55.9937628 C29.6106005,56.0118517 29.4971913,55.9923021 29.3990907,55.9382482 L25.2412648,53.6472637 L25.2412648,53.6472637 C25.0910471,53.5644929 24.9088879,53.5644929 24.7586701,53.6472637 Z"
                  id="Star"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Star
