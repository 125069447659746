import React, { Component } from 'react'

class GithubMark extends Component {
  static defaultProps = {
    fillColor: '#00125F',
    height: 24,
    width: 24,
  }

  render() {
    const { className, fillColor, height, width } = this.props

    return (
      <svg
        className={className}
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>github-mark</title>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          fillOpacity="0.3"
        >
          <g
            id="osquery-io-again-again"
            transform="translate(-1103.000000, -28.000000)"
            fill={fillColor}
          >
            <g id="nav-top" transform="translate(249.000000, 15.000000)">
              <g id="Group-2" transform="translate(452.000000, 0.000000)">
                <g id="Group-8" transform="translate(402.000000, 13.000000)">
                  <path
                    d="M12,0 C5.3724,0 0,5.50578041 0,12.2979236 C0,17.7336059 3.444,22.3453272 8.208,23.9809511 C8.808,24.0793345 9,23.6980988 9,23.3660549 L9,21.2877058 C5.676,22.0255812 4.968,19.639784 4.968,19.639784 C4.416,18.2132249 3.636,17.8319893 3.636,17.8319893 C2.544,17.069518 3.72,17.0941138 3.72,17.0941138 C4.92,17.1801993 5.556,18.3608 5.556,18.3608 C6.6,20.2300844 8.364,19.6766778 9.048,19.3815276 C9.156,18.5821626 9.468,18.041054 9.804,17.7336059 C7.14,17.4261578 4.344,16.3685364 4.344,11.6830275 C4.344,10.3179579 4.8,9.22344272 5.58,8.35029015 C5.46,8.04284206 5.04,6.763858 5.7,5.10363831 C5.7,5.10363831 6.708,4.77159437 9,6.35802652 C9.948,6.0874722 10.98,5.95219504 12,5.95219504 C13.02,5.95219504 14.052,6.0874722 15,6.35802652 C17.292,4.77159437 18.3,5.10363831 18.3,5.10363831 C18.96,6.763858 18.54,8.04284206 18.42,8.35029015 C19.2,9.22344272 19.656,10.3179579 19.656,11.6830275 C19.656,16.3808343 16.848,17.4138599 14.172,17.721308 C14.604,18.1025436 15,18.8527169 15,19.9964238 L15,23.3660549 C15,23.6980988 15.192,24.0916324 15.804,23.9809511 C20.568,22.3330293 24,17.7336059 24,12.2979236 C24,5.50578041 18.6264,0 12,0"
                    id="github-mark"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default GithubMark
