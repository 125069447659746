import React, { Component } from 'react'

class SlackText extends Component {
  static defaultProps = {
    fillColor: '#202532',
    height: 55,
    width: 157,
  }

  render() {
    const { className, fillColor, height, width } = this.props

    return (
      <svg
        className={className}
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>slack-bw</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="osquery-io-again-again"
            transform="translate(-377.000000, -3880.000000)"
            fillRule="nonzero"
            fill={fillColor}
          >
            <g id="Group-19" transform="translate(305.000000, 3552.000000)">
              <g id="Group-72" transform="translate(0.000000, 167.000000)">
                <g id="Group-71" transform="translate(72.000000, 81.000000)">
                  <g id="Group-73">
                    <g id="slack-bw" transform="translate(0.000000, 80.000000)">
                      <path
                        d="M27.6181818,18.7296256 C29.6909091,19.6445785 29.8545455,20.2904277 28.2181818,23.3582112 C26.5272727,26.4798154 26.1454545,26.6950985 24.0727273,25.887787 C21.5090909,24.8113718 18.1818182,24.0040603 16.0545455,24.0040603 C12.5636364,24.0040603 10.2727273,25.2419379 10.2727273,27.1256646 C10.2727273,33.3150523 30.2363636,29.978165 30.2363636,43.1104312 C30.2363636,49.7303851 24.4545455,54.1436877 15.8363636,54.1436877 C11.3090909,54.1436877 5.69090909,52.6367063 1.81818182,50.6991588 C-0.0909090909,49.7303851 -0.254545455,49.1921775 1.43636364,46.0167524 C2.85454545,43.2718935 3.34545455,42.9489689 5.41818182,43.8101011 C8.69090909,45.209441 12.8909091,46.339677 15.6727273,46.339677 C18.8363636,46.339677 20.9636364,45.0479787 20.9636364,43.2180727 C20.9636364,37.243968 0.618181818,40.0964685 0.618181818,27.3409476 C0.618181818,20.5595315 6.34545455,16.0385874 14.9090909,16.0385874 C19.0545455,15.9847666 24.1272727,17.1688234 27.6181818,18.7296256 Z"
                        id="Shape"
                      />
                      <path
                        d="M43.7636364,3.12160426 L43.7636364,52.2061402 C43.7636364,52.9596309 42.9454545,53.7131215 41.8545455,53.7131215 L36.6181818,53.7131215 C35.4727273,53.7131215 34.7090909,52.9058101 34.7090909,52.2061402 L34.7090909,3.12160426 C34.7090909,0.69966992 35.4181818,0.484386868 39.2363636,0.484386868 C43.6,0.430566105 43.7636364,0.753490683 43.7636364,3.12160426 Z"
                        id="Shape"
                      />
                      <path
                        d="M80.9636364,30.677835 L80.9636364,51.8293948 C80.9636364,52.9596309 80.1454545,53.7131215 79.0545455,53.7131215 L73.8727273,53.7131215 C72.6727273,53.7131215 71.8545455,52.8519893 71.9636364,51.6679326 L72.0181818,49.4074605 C69.2363636,52.475244 65.2,54.0898669 61.1636364,54.0898669 C53.3636364,54.0898669 48.1272727,49.6227436 48.1272727,43.0027897 C48.1272727,35.9522697 54.0181818,31.1622218 62.9090909,31.1622218 C66.2909091,31.1622218 69.3454545,31.7542502 71.8545455,32.7768447 L71.8545455,30.3549104 C71.8545455,26.4798154 68.7454545,24.1655226 63.4545455,24.1655226 C61,24.1655226 57.9454545,25.1342964 55.5454545,26.5336362 C53.6909091,27.5562307 53.2545455,27.5024099 51.2363636,24.542268 C49.2727273,21.582126 49.3272727,21.0439184 51.2363636,19.8060408 C54.8909091,17.491748 59.8,15.9847666 64.3272727,15.9847666 C74.5272727,15.9847666 80.9636364,21.4206637 80.9636364,30.677835 Z M56.8545455,42.9489689 C56.8545455,45.4785448 59.0363636,47.1469885 62.2545455,47.1469885 C66.1818182,47.1469885 69.7818182,45.2632617 71.8545455,42.0878367 L71.8545455,38.8047702 C69.7818182,37.9974587 67.2181818,37.5668926 64.9818182,37.5668926 C60.0727273,37.5668926 56.8545455,39.8273647 56.8545455,42.9489689 Z"
                        id="Shape"
                      />
                      <path
                        d="M116.418182,18.7834463 C118.327273,19.8598616 118.381818,20.45189 116.309091,23.627315 C114.345455,26.6412777 114.018182,26.80274 111.890909,25.7801455 C110.309091,24.9728341 107.745455,24.2731641 105.672727,24.2731641 C99.1272727,24.2731641 94.7636364,28.5250044 94.7636364,34.983496 C94.7636364,41.6572706 99.1272727,46.1782147 105.672727,46.1782147 C107.963636,46.1782147 110.8,45.3170825 112.654545,44.294488 C114.563636,43.2180727 114.945455,43.2718935 116.963636,46.1782147 C118.763636,48.8692529 118.763636,49.515102 117.127273,50.6453381 C114.181818,52.6367063 109.6,54.1436877 105.509091,54.1436877 C93.4,54.1436877 85.2727273,46.5011393 85.2727273,34.983496 C85.2727273,23.5734942 93.4,15.9847666 105.618182,15.9847666 C109.327273,15.9847666 113.690909,17.2226442 116.418182,18.7834463 Z"
                        id="Shape"
                      />
                      <path
                        d="M156.018182,49.0845359 C157.545455,50.9682626 156.945455,51.6679326 153.127273,53.0134516 C149.254545,54.4127915 148.709091,54.3051499 147.345455,52.5828855 L136.490909,38.3203833 L131.636364,42.9489689 L131.636364,52.2061402 C131.636364,52.9596309 130.818182,53.7131215 129.727273,53.7131215 L124.490909,53.7131215 C123.345455,53.7131215 122.581818,52.9058101 122.581818,52.2061402 L122.581818,3.12160426 C122.581818,0.69966992 123.290909,0.484386868 127.109091,0.484386868 C131.527273,0.484386868 131.636364,0.807311446 131.636364,3.12160426 L131.636364,31.0007595 L146.472727,16.9535404 C148.109091,15.446559 149.036364,15.5542005 151.927273,17.4379272 C155.145455,19.4831162 155.363636,20.0751446 153.836364,21.5283052 L142.818182,32.1848163 L156.018182,49.0845359 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default SlackText
