import React, { Component } from 'react'

class Slack extends Component {
  static defaultProps = {
    height: 135,
    width: 157,
  }

  render() {
    const { className, height, width } = this.props

    return (
      <svg
        className={className}
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>slack-logo-svg</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="osquery-io-again-again"
            transform="translate(-377.000000, -3722.000000)"
            fillRule="nonzero"
          >
            <g id="additional-resources" transform="translate(305.000000, 3492.000000)">
              <g id="join-osquery-slack" transform="translate(0.000000, 149.000000)">
                <g id="slack-logo-svg" transform="translate(72.000000, 81.000000)">
                  <g id="Group-73">
                    <g id="slack-bw" transform="translate(0.000000, 80.000000)" fill="#202532">
                      <path
                        d="M27.6181818,18.7296256 C29.6909091,19.6445785 29.8545455,20.2904277 28.2181818,23.3582112 C26.5272727,26.4798154 26.1454545,26.6950985 24.0727273,25.887787 C21.5090909,24.8113718 18.1818182,24.0040603 16.0545455,24.0040603 C12.5636364,24.0040603 10.2727273,25.2419379 10.2727273,27.1256646 C10.2727273,33.3150523 30.2363636,29.978165 30.2363636,43.1104312 C30.2363636,49.7303851 24.4545455,54.1436877 15.8363636,54.1436877 C11.3090909,54.1436877 5.69090909,52.6367063 1.81818182,50.6991588 C-0.0909090909,49.7303851 -0.254545455,49.1921775 1.43636364,46.0167524 C2.85454545,43.2718935 3.34545455,42.9489689 5.41818182,43.8101011 C8.69090909,45.209441 12.8909091,46.339677 15.6727273,46.339677 C18.8363636,46.339677 20.9636364,45.0479787 20.9636364,43.2180727 C20.9636364,37.243968 0.618181818,40.0964685 0.618181818,27.3409476 C0.618181818,20.5595315 6.34545455,16.0385874 14.9090909,16.0385874 C19.0545455,15.9847666 24.1272727,17.1688234 27.6181818,18.7296256 Z"
                        id="Shape"
                      />
                      <path
                        d="M43.7636364,3.12160426 L43.7636364,52.2061402 C43.7636364,52.9596309 42.9454545,53.7131215 41.8545455,53.7131215 L36.6181818,53.7131215 C35.4727273,53.7131215 34.7090909,52.9058101 34.7090909,52.2061402 L34.7090909,3.12160426 C34.7090909,0.69966992 35.4181818,0.484386868 39.2363636,0.484386868 C43.6,0.430566105 43.7636364,0.753490683 43.7636364,3.12160426 Z"
                        id="Shape"
                      />
                      <path
                        d="M80.9636364,30.677835 L80.9636364,51.8293948 C80.9636364,52.9596309 80.1454545,53.7131215 79.0545455,53.7131215 L73.8727273,53.7131215 C72.6727273,53.7131215 71.8545455,52.8519893 71.9636364,51.6679326 L72.0181818,49.4074605 C69.2363636,52.475244 65.2,54.0898669 61.1636364,54.0898669 C53.3636364,54.0898669 48.1272727,49.6227436 48.1272727,43.0027897 C48.1272727,35.9522697 54.0181818,31.1622218 62.9090909,31.1622218 C66.2909091,31.1622218 69.3454545,31.7542502 71.8545455,32.7768447 L71.8545455,30.3549104 C71.8545455,26.4798154 68.7454545,24.1655226 63.4545455,24.1655226 C61,24.1655226 57.9454545,25.1342964 55.5454545,26.5336362 C53.6909091,27.5562307 53.2545455,27.5024099 51.2363636,24.542268 C49.2727273,21.582126 49.3272727,21.0439184 51.2363636,19.8060408 C54.8909091,17.491748 59.8,15.9847666 64.3272727,15.9847666 C74.5272727,15.9847666 80.9636364,21.4206637 80.9636364,30.677835 Z M56.8545455,42.9489689 C56.8545455,45.4785448 59.0363636,47.1469885 62.2545455,47.1469885 C66.1818182,47.1469885 69.7818182,45.2632617 71.8545455,42.0878367 L71.8545455,38.8047702 C69.7818182,37.9974587 67.2181818,37.5668926 64.9818182,37.5668926 C60.0727273,37.5668926 56.8545455,39.8273647 56.8545455,42.9489689 Z"
                        id="Shape"
                      />
                      <path
                        d="M116.418182,18.7834463 C118.327273,19.8598616 118.381818,20.45189 116.309091,23.627315 C114.345455,26.6412777 114.018182,26.80274 111.890909,25.7801455 C110.309091,24.9728341 107.745455,24.2731641 105.672727,24.2731641 C99.1272727,24.2731641 94.7636364,28.5250044 94.7636364,34.983496 C94.7636364,41.6572706 99.1272727,46.1782147 105.672727,46.1782147 C107.963636,46.1782147 110.8,45.3170825 112.654545,44.294488 C114.563636,43.2180727 114.945455,43.2718935 116.963636,46.1782147 C118.763636,48.8692529 118.763636,49.515102 117.127273,50.6453381 C114.181818,52.6367063 109.6,54.1436877 105.509091,54.1436877 C93.4,54.1436877 85.2727273,46.5011393 85.2727273,34.983496 C85.2727273,23.5734942 93.4,15.9847666 105.618182,15.9847666 C109.327273,15.9847666 113.690909,17.2226442 116.418182,18.7834463 Z"
                        id="Shape"
                      />
                      <path
                        d="M156.018182,49.0845359 C157.545455,50.9682626 156.945455,51.6679326 153.127273,53.0134516 C149.254545,54.4127915 148.709091,54.3051499 147.345455,52.5828855 L136.490909,38.3203833 L131.636364,42.9489689 L131.636364,52.2061402 C131.636364,52.9596309 130.818182,53.7131215 129.727273,53.7131215 L124.490909,53.7131215 C123.345455,53.7131215 122.581818,52.9058101 122.581818,52.2061402 L122.581818,3.12160426 C122.581818,0.69966992 123.290909,0.484386868 127.109091,0.484386868 C131.527273,0.484386868 131.636364,0.807311446 131.636364,3.12160426 L131.636364,31.0007595 L146.472727,16.9535404 C148.109091,15.446559 149.036364,15.5542005 151.927273,17.4379272 C155.145455,19.4831162 155.363636,20.0751446 153.836364,21.5283052 L142.818182,32.1848163 L156.018182,49.0845359 Z"
                        id="Shape"
                      />
                    </g>
                    <g id="Group" transform="translate(45.000000, 0.000000)">
                      <path
                        d="M43.5272727,4.35948181 C42.4909091,1.29169831 39.1636364,-0.376745342 36.0545455,0.592028394 C32.9454545,1.61462289 31.2545455,4.89768944 32.2363636,7.96547294 L47.5636364,54.4666122 C48.6,57.3191127 51.7636364,58.9337356 54.7636364,58.0726034 C57.9272727,57.1576504 59.8363636,53.8745838 58.8,50.8606211 C58.8,50.7529796 43.5272727,4.35948181 43.5272727,4.35948181 Z"
                        id="Shape"
                        fill="#ECB32D"
                      />
                      <path
                        d="M19.8,11.9482094 C18.7636364,8.88042591 15.4363636,7.21198225 12.3272727,8.18075599 C9.21818182,9.20335049 7.52727273,12.486417 8.50909091,15.5542005 L23.8363636,62.0553398 C24.8727273,64.9078403 28.0363636,66.5224632 31.0363636,65.661331 C34.2,64.746378 36.1090909,61.4633114 35.0727273,58.4493487 C35.0727273,58.3417072 19.8,11.9482094 19.8,11.9482094 Z"
                        id="Shape"
                        fill="#63C1A0"
                      />
                      <path
                        d="M62.8363636,42.8413274 C65.9454545,41.8187329 67.6363636,38.5356664 66.6545455,35.4678829 C65.6181818,32.4000994 62.2909091,30.7316557 59.1818182,31.7004295 L12,46.8778846 C9.10909091,47.9004791 7.47272727,51.0220834 8.34545455,53.9822254 C9.27272727,57.1038296 12.6,58.9875563 15.6545455,57.9649618 C15.7636364,57.9649618 62.8363636,42.8413274 62.8363636,42.8413274 Z"
                        id="Shape"
                        fill="#E01A59"
                      />
                      <path
                        d="M21.8181818,56.0274144 C24.8727273,55.0586406 28.8545455,53.7669423 33.1090909,52.4214232 C32.1272727,49.4074605 30.8181818,45.4785448 29.4545455,41.2805253 L18.1636364,44.8865164 L21.8181818,56.0274144 Z"
                        id="Shape"
                        fillOpacity="0.300000012"
                        fill="#331433"
                      />
                      <path
                        d="M45.6,48.384866 C49.8545455,47.0393469 53.8363636,45.7476486 56.8909091,44.7788749 C55.9090909,41.7649121 54.6,37.8359964 53.2363636,33.6379769 L41.9454545,37.243968 L45.6,48.384866 Z"
                        id="Shape"
                        fill="#BD1E24"
                      />
                      <path
                        d="M55.0909091,19.4292955 C58.2,18.406701 59.8909091,15.1236344 58.9090909,12.0558509 C57.8727273,8.98806743 54.5454545,7.31962378 51.4363636,8.28839751 L4.30909091,23.4120319 C1.41818182,24.4346264 -0.218181818,27.5562307 0.654545455,30.5163727 C1.58181818,33.6379769 4.90909091,35.5217036 7.96363636,34.4991091 C8.07272727,34.4991091 55.0909091,19.4292955 55.0909091,19.4292955 Z"
                        id="Shape"
                        fill="#89D3DF"
                      />
                      <path
                        d="M14.1272727,32.5615617 C17.1818182,31.5927879 21.1636364,30.3010896 25.4181818,28.9555705 C24.0545455,24.757551 22.7454545,20.8286353 21.7636364,17.8146726 L10.4727273,21.4206637 L14.1272727,32.5615617 Z"
                        id="Shape"
                        fillOpacity="0.800000012"
                        fill="#258B74"
                      />
                      <path
                        d="M37.8545455,24.9728341 C42.1090909,23.627315 46.0909091,22.3356167 49.1454545,21.3668429 C47.7818182,17.1688234 46.4727273,13.2399077 45.4909091,10.225945 L34.2,13.8319361 L37.8545455,24.9728341 Z"
                        id="Shape"
                        fill="#819C3C"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Slack
