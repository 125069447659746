// Add your event's filename to this list

export default [
  'querycon18',
  'kolide-osquery-workshop',
  'acod2019',
  'bsidessf2019',
  'altseccon19',
  'querycon19',
  'uptycs-free-osquery-workshop',
  'hackmiami19',
  'osquery@scale',
  'bsidesslc20',
  'osquery@scale2021',
  'osquery@scale2022',
]
