import React, { Component } from 'react'

class Apple extends Component {
  static defaultProps = {
    fillColor: '#00125F',
    height: 22,
    width: 18,
  }

  render() {
    const { className, fillColor, height, width } = this.props

    return (
      <svg
        className={className}
        height={`${height}px`}
        version="1.1"
        viewBox={`0 0 ${width} ${height}`}
        width={`${width}px`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>apple</title>

        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            id="osquery-io-again-again"
            transform="translate(-712.000000, -369.000000)"
            fill={fillColor}
          >
            <g id="h1-page-title" transform="translate(474.000000, 253.000000)">
              <g id="compatible-platforms" transform="translate(238.000000, 115.000000)">
                <path
                  d="M12.8549612,1.77141078 C12.8549612,2.27966366 12.7278757,2.78791654 12.4737047,3.42323264 C12.2195337,4.05854874 11.8382772,4.56680162 11.3299351,5.0750545 C9.98120295,6.11223208 9.3287301,6.20857258 8.66113947,6.21862348 C8.66113947,5.0750545 9.042396,4.05854874 9.55073803,3.1691062 C10.3132511,2.15260044 11.3299351,1.64434756 12.7278757,1.26315789 C12.8549612,1.50832306 12.8549612,1.64434756 12.8549612,1.77141078 Z M7.26319889,7.10806602 C8.15279744,7.36219246 8.78822498,7.48925568 9.042396,7.48925568 C9.42365252,7.48925568 9.93199455,7.36219246 10.8215931,7.10806602 C11.7111917,6.85393958 12.4737047,6.72687636 12.9820467,6.72687636 C13.9987308,6.72687636 14.8883294,6.9810028 15.6508424,7.48925568 C15.9050134,7.6163189 16.794612,8.37869822 17.048783,8.75988789 C16.4133554,9.26814077 15.9050134,9.77639365 15.6508424,10.2846465 C15.1425004,11.0470258 14.7612438,11.9364684 14.7612438,12.8259109 C14.7612438,13.8424167 15.0154149,14.8589225 15.6508424,15.6213018 C16.2862699,16.3836811 16.9216975,17.0189972 17.6842105,17.1460604 C17.557125,17.6543133 17.3195131,18.6708191 16.1591844,20.3226409 C14.9988558,21.9744628 13.9987308,22.7368421 12.8549612,22.7368421 C12.3466192,22.7368421 11.8382772,22.6097789 11.0757641,22.3556524 C10.3132511,22.101526 9.67782354,21.9744628 9.1694815,21.9744628 C8.66113947,21.9744628 8.02571193,22.101526 7.39028439,22.3556524 C6.62777135,22.6097789 6.11942932,22.7368421 5.73817279,22.7368421 C4.46731772,22.7368421 3.19646264,21.5932731 1.92560756,19.4331984 C0.654752481,17.2731236 0.0193249423,15.1130489 0.0193249423,13.0800374 C-0.107760565,11.4282155 0.400581466,9.77639365 1.29018002,8.63282467 C2.30686408,7.36219246 3.45063365,6.72687636 4.97565975,6.72687636 C5.61108729,6.72687636 6.37360033,6.85393958 7.26319889,7.10806602 Z"
                  id="apple"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default Apple
